<template>
	<div id="login">
		<div class="logo">
			<img :src="$system_.state.applic_logo" alt="logo">
		</div>
		<div class="title">{{$system_.state.applic_name}}</div>
		<div class="main">
			<div id="qywork_reg" class="qy_work"></div>
			<div class="footer">
				<div>开发者：{{$system_.state.copyright_company}}</div>
				<div>{{$system_.state.applic_name}} {{$system_.state.soft_info}}
					<a target="_blank" href="https://beian.miit.gov.cn"
						style="color: #737C84;text-decoration: none;">{{$system_.state.system_record_no}}</a>
				</div>
				<div>Copyright {{$system_.state.copyright_company}} {{$system_.state.copyright_telephone}}</div>

			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
	import {
		getCurrentInstance,
		defineComponent,
		ref,
		reactive
	} from 'vue'
	export default defineComponent({
		name: 'Login',
		setup() {
			// 访问 globalProperties
			const ctx = getCurrentInstance().appContext.config.globalProperties

			// 获取企业微信登录配置文件
			ctx.$request_.post(ctx.$api_.state.Home.Login.login_qywork_info.url)
				.then((respon) => {
					if (respon.Code === ctx.$code_.state.success) {
						// 调起企业微信扫码
						initQyWorkLogin(respon.Data)
					} else {
						ctx.$notify({
							title: '温馨提示',
							message: respon.Message,
							type: 'warning'
						})
					}
				})

			const initQyWorkLogin = (qyConf) => {
				// 调用企业微信扫码登录窗口
				window.WwLogin({
					id: 'qywork_reg',
					appid: qyConf.appid,
					agentid: qyConf.agentid,
					redirect_uri: encodeURIComponent(ctx.$api_.state.Pub.current_site + '/QrcodeAuth'),
					state: qyConf.state
				})
			}
		}
	})
</script>

<style lang="less">
	#login {
		width: 100%;
		height: 100%;
		background: url(https://z.ygddzy.cn/yc/work/login_bg.jpg) no-repeat;
		background-size: 100%;
		-webkit-touch-callout: none;
		-webkit-user-select: none;
		-khtml-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;

		.logo {
			position: absolute;
			top: 1%;
			left: 1%;
			width: 10.4vw;

			img {
				width: 100%;
				font-size: 0.8vw;
				color: #ffffff;
			}
		}

		.title {
			font-size: 1.5vw;
			color: #ffffff;
			text-align: center;
			padding-top: 4vw;
		}

		.main {
			position: relative;
			margin: 0 auto;
			background: #ffffff;
			padding: 0.5vw 0;
			width: 22%;
			height: 55%;
			margin-top: 2%;
			border-radius: 10px;

			.qy_work {
				margin-top: 5%;
				text-align: center;
				height: 70%;
			}

			.footer {
				position: absolute;
				bottom: 1%;
				width: 100%;
				color: #737C84;

				div {
					text-align: center;
					font-size: 0.8vw;
					line-height: 24px;
				}
			}
		}
	}
</style>
